@import '~styles/mixins';

.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(19, 17, 28, 0.5);
  backdrop-filter: blur(rem(10px));
  display: none;
  pointer-events: none;
  transition: opacity 0.3s ease;
  &Open {
    display: block;
    pointer-events: auto;
  }
}

.modalContainer{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 rem(20px);
  width: rem(640px);
  max-width: 100%;
}

.modal {
  width: 100%;
  position: relative;
  &::before {
    z-index: 0;
    content: '';
    position: absolute;
    top: rem(-1px);
    left: rem(-1px);
    width: calc(100% + rem(2px));
    height: calc(100% + rem(2px));
    border-radius: rem(15px);
    background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
  }
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: rem(15px);
  background-color: var(--background-color);
  padding: rem(20px);
  display: flex;
  flex-direction: column;
  gap: rem(20px);
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: rem(10px);
}

.heading {
  font-size: rem(15px);
  line-height: rem(20px);
  font-weight: 600;
}
