@import "mixins";
@import "variables";

:root {
  touch-action: var(--touch-action, none);
  --background-color: #13111c;
  --bottom-height: rem(
    150px
  ); // Максимальная высота блока на всех 4-х экранах (для того, чтобы не "прыгал" блок с картинкой)
  --bottom-padding: rem(50px);
  --swiper-navigation-color: #4628ff;
  --swiper-theme-color: #4628ff;
  @media (max-width: rem(767px)) {
    --bottom-height: rem(160px);
    --bottom-padding: rem(25px);
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html {
  font-size: $html-font-size;
}
body {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  display: none;
  width: 0px;
  height: 0px;
}
::-moz-scrollbar {
  width: 0px;
  height: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  user-select: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  background-color: transparent;
  border: none;
}

.input-range__track.input-range__track--background {
  height: rem(3px) !important;
  background-color: hsla(295, 100%, 18%, 1);
}

.input-range__track.input-range__track--active {
  height: rem(3px);
  background-color: hsla(295, 100%, 42%, 1) !important;
}

.input-range__slider {
  background-color: hsla(295, 100%, 42%, 1) !important;
  z-index: 1;
}
