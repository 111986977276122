.main {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  // padding-bottom: var(--bottom-padding);
  &WithGradient {
    &::after {
      pointer-events: none;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      width: 100%;
      // height: calc(var(--bottom-padding) + var(--bottom-height) + 25px);
      // background-image: linear-gradient(to top, var(--background-color) 0%, var(--background-color) var(--bottom-padding), transparent 100%);
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}
