@import '~styles/mixins';

.fixed {
    pointer-events: none;
    z-index: 10;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: rem(273px);
    background-image: linear-gradient(
      180deg,
      rgba(19, 17, 28, 0) 0%,
      #13111c 84.85%
    );
    .button {
      pointer-events: all;
      bottom: rem(70px);
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
  
      min-width: rem(350px);
      border-radius: rem(30px);
      @media (max-width: rem(1150px)) {
        bottom: rem(110px);
      }
      @media (max-width: rem(575px)) {
        min-width: auto;
        width: 100%;
      }
    }
  }