@import './mixins';

.button {
  position: relative;
  text-align: center;
  border-radius: rem(15px);
  padding: rem(13px) rem(25px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(10px);
  background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
  transition: opacity 0.3s ease;
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &Outlined {
    &::before {
      content: '';
      z-index: 0;
      position: absolute;
      top: 2px;
      bottom: 2px;
      left: 2px;
      right: 2px;
      background-color: var(--background-color);
      border-radius: rem(15px);
    }
  }
  &Text {
    padding: rem(5px);
    opacity: 0.5;
    background: transparent;
    &::before {
      display: none;
    }
    &:hover {
      opacity: 1;
    }
    .buttonLabel {
      font-size: rem(15px);
      font-weight: 500;
      line-height: rem(18px);
    }
  }
  & > * {
    position: relative;
  }
  & > svg {
    @media (max-width: rem(767px)) {
      width: rem(18px);
      height: rem(18px);
    }
  }
  &Label {
    font-size: rem(16px);
    font-weight: 600;
    line-height: rem(24px);
    @media (max-width: rem(767px)) {
      font-size: rem(14px);
      line-height: rem(18px);
    }
  }
}

.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: rem(3px);
  padding: rem(6px);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(#c400d6, 0.1);
  }
}

.select {
  padding: rem(15px) rem(25px);
  background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
  color: #ffffff;
  font-size: rem(16px);
  font-weight: 600;
  line-height: rem(20px);
  border: none;
  border-radius: rem(3px);
  @media (max-width: rem(767px)) {
    font-size: rem(12px);
    line-height: rem(16px);
  }
  option {
    color: #000000;
  }
}
