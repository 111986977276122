.outerWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.innerWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 25px;
  overflow: hidden;
}

.image {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform-origin: 0 0;
}
