@import '~styles/mixins';

.finishingStage {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: rem(20px);
}

.navigationPanel {
  padding: rem(27px) 0 rem(26px) 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: rem(840px)) {
    padding: rem(3px) 0 rem(26px) 0;
  }

  .goBackButton {
    width: rem(50px);
    height: rem(50px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: rem(20px);
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: rem(40px);
  width: 100%;
  height: 100%;

  @media (max-width: rem(1150px)) {
    gap: rem(20px);
  }
  @media (max-width: rem(1100px)) {
    display: flex;
    flex-direction: column;
    max-width: rem(660px);
  }

  .mainImageWrapper {
    width: 100%;
    // height: 100%;
    border-radius: rem(25px);
    flex-shrink: 0;
    overflow: hidden;

    @media (max-width: rem(1100px)) {
      min-height: rem(300px);
    }
    @media (max-width: rem(400px)) {
      min-height: rem(200px);
    }

    .mainImage {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

// .request {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   &Text {
//     display: -webkit-box;
//     overflow: hidden;
//     font-size: rem(20px);
//     line-height: rem(24px);
//     font-weight: 400;
//     -webkit-box-orient: vertical;
//     -webkit-line-clamp: 1;
//     @media (max-width: rem(767px)) {
//       font-size: rem(15px);
//       line-height: rem(18px);
//     }
//   }
// }

.controlPanel {
  background-color: hsla(251, 27%, 11%, 1);
  border-radius: rem(30px);
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: rem(46px) rem(50px) rem(43px) rem(50px);

  @media (max-width: rem(767px)) {
    padding: rem(26px) rem(30px) rem(23px) rem(30px);
  }

  .requestWrapper {
    height: rem(124px);
    position: relative;
    &LeftPadded {
      .input {
        padding-left: rem(50px);
      }
    }
    &RightPadded {
      .input {
        padding-right: rem(50px);
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
      border-radius: rem(30px);
    }
    .requestInput {
      height: rem(124px);
      position: relative;
      width: 100%;
      z-index: 1;
      color: hsla(240, 6%, 83%, 1);
      text-align: center;
      font-weight: 500;
      font-size: rem(18px);
      line-height: rem(22px);
      background-color: hsla(251, 27%, 11%, 1);
      border: none;
      padding: rem(29px) rem(42px);
      border-radius: rem(30px);
      outline: rem(1px) solid transparent;
      transition: outline-color 0.3s ease;
      resize: none;
      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
      &:focus-visible {
        outline: rem(1px) solid #c400d6;
      }

      @media (max-width: rem(767px)) {
        padding: rem(22px) rem(22px);
      }
    }
  }

  .buttons {
    display: grid;
    grid-template-columns: rem(50px) 1fr rem(50px);
    gap: rem(15px);
    align-items: center;
    align-self: center;
    justify-content: space-between;
    width: 100%;
    max-width: rem(380px);
    padding: rem(30px) 0 rem(90px) 0;

    @media (max-width: rem(767px)) {
      padding: rem(30px) 0 rem(30px) 0;
    }

    .button {
      display: flex;
      flex-direction: row;
      gap: rem(10px);
      align-items: center;
      justify-content: center;
      border-radius: rem(20px);
      background-color: hsla(0, 0%, 100%, 0.1);
      width: 100%;
      height: rem(40px);
      font-weight: 600;
      font-size: rem(15px);
      line-height: rem(18px);
    }

    .iconButton {
      border-radius: rem(20px);
      background-color: hsla(0, 0%, 100%, 0.1);
      width: rem(50px);
      height: rem(40px);
    }
  }
}

.controls {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: rem(767px)) {
    margin-top: rem(15px);
  }
  &Button {
    padding: rem(12px) rem(6px);
    // height: 100%;
  }
}

.copyButton {
  margin-left: rem(8px);
  display: inline-block;
}

.rightPart {
  display: flex;
  flex-direction: column;
  gap: rem(40px);

  @media (max-width: rem(1100px)) {
    flex-direction: column-reverse;
  }
  
  @media (max-width: rem(767px)) {
    gap: rem(20px);
  }
}

.slider {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: rem(10px);

  .control {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .arrow {
      fill: hsla(240, 6%, 83%, 0.5);
    }
  }

  .sliders {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    // grid-template-rows: rem(245px);
    gap: rem(19px);

    .slideWrapper {
      width: 100%;
      // height: 100%;
      position: relative;
      border-radius: rem(25px);
      overflow: hidden;

      .image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .loaderWrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: rem(40px);
        height: rem(40px);
      }

      .blocked {
        z-index: 2;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: rem(11px);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        backdrop-filter: blur(rem(15px));
      }
    }
  }
}
