.slider {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  transform: translateX(-50%);
  @media (max-width: 767px) {
    width: 35px;
  }
  &Button {
    position: absolute;
    bottom: 56px;
    // 56px = 10px (отступ от края блока до нижнего края кнопки resetButton)
    //      + 36px (высота кнопки resetButton)
    //      + 10px (отступ от верхнего края кнопки resetButton до кнопки слайдера)
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 50px;
    border-radius: 15px;
    color: #000;
    background-color: #ffffff;
    @media (max-width: 767px) {
      width: 35px;
      height: 35px;
    }
  }
  &Arrow {
    position: absolute;
    top: 50%;
    width: 60px;
    height: 60px;
    transform: translate(-50%, -50%);
    fill: currentColor;
    color: #4628ff;
    @media (max-width: 767px) {
      width: 35px;
      height: 35px;
    }
    &Left {
      left: 30%;
    }
    &Right {
      left: 70%;
    }
  }
  &Rail {
    width: 2px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
  }
}
