@import '~styles/mixins';

.form {
  display: flex;
  flex-direction: column;
  gap: rem(10px);
  &Input {

  }
  &ForgotPassword {
    margin-top: rem(5px);
    margin-left: rem(10px);
    align-self: flex-start;
  }

  .logOut{
    align-self: center;
    width: 100%;
  }

  &Submit {
    margin-top: rem(25px);
  }
}

.buttons{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}