@import '~styles/mixins';

.controls {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: rem(15px);
  @media (max-width: rem(767px)) {
    gap: rem(10px);
  }
}
