
@import '~styles/mixins';

.heading {
  font-weight: 600;
  font-size: rem(20px);
  line-height: rem(24px);
}

.powerSelection {
    padding-top: rem(48px);
    padding-bottom: rem(40px);
    display: flex;
    flex-direction: column;
    gap: rem(20px);

    .rangeWrapper {
      position: relative;

      .rangeCircle {
        width: rem(10px);
        height: rem(10px);
        border-radius: 50%;
        background-color: hsla(295, 100%, 42%, 1);
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 0.3s;

        &:nth-child(1) {
          left: 0;
        }
        &:nth-child(2) {
          left: 25%;
        }
        &:nth-child(3) {
          left: 50%;
        }
        &:nth-child(4) {
          left: 75%;
        }
        &:nth-child(5) {
          left: 100%;
        }
      }
    }
  }