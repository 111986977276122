@import '~styles/mixins';

.main {
    height: 100%;
    flex-grow: 1;
    padding-bottom: var(--bottom-padding);
  }
  
  .container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .loader {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
  
  .errorMessage {
    text-align: center;
  }
  
  .errorLink {
    margin-top: rem(25px);
    margin-left: auto;
    margin-right: auto;
    max-width: rem(350px);
  }
  