@import '~styles/mixins';

.suggestionsWrapper {
    max-width: 100%;
    width: 100%;
    overflow-x: scroll;
  }

  .suggestionsList {
    padding-top: rem(20px);
    display: flex;
    flex-direction: row;
    gap: rem(15px);
    list-style: none;
    

    .suggestion {
      flex-shrink: 0;
      position: relative;
      max-height: rem(60px);
      padding: rem(7px) rem(10px);
      background-image: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
      border-radius: rem(20px);
      transition: 0.3s;
      cursor: pointer;
      overflow: hidden;
      max-width: 20%;

      @media (max-width: rem(1150px)) {
        max-width: 25%;
      }
      @media (max-width: rem(1150px)) {
        max-width: 30%;
      }
      @media (max-width: rem(520px)) {
        max-width: 40%;
      }

      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-clamp: 3;
        box-orient: vertical;

        position: relative;
        font-weight: 500;
        font-size: rem(12px);
        line-height: rem(15px);
        z-index: 2;
      }
      &::after {
        z-index: 1;
        border-radius: rem(20px);
        position: absolute;
        content: '';
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        background-color: var(--background-color);
        left: 1px;
        top: 1px;
        transition: 0.5s;
      }

      &:hover::after {
        opacity: 0;
      }
    }
  }
