@import '~styles/mixins';

.addImage {
  background-color: hsla(251, 24%, 9%, 1);
  width: 100%;
  height: 100%;
  border: 1px solid hsla(295, 100%, 42%, 1);
  border-radius: rem(25px);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: rem(22.5px);

  &::before {
    transition: 0.3s;
    z-index: 1;
    position: absolute;
    left: 40%;
    top: 5%;
    content: '';
    width: 60%;
    height: 100%;
    background-image: radial-gradient(
      66.38% 66.38% at 41.32% 33.62%,
      rgba(70, 40, 255, 0.75) 0%,
      rgba(19, 17, 28, 0.75) 100%
    );
    opacity: 0.5;
    filter: blur(rem(75px));
    transform: rotate(26.75deg);
  }
  &:hover::before {
    opacity: 0.8;
  }
  &::after {
    transition: 0.3s;
    z-index: 1;
    position: absolute;
    left: 0%;
    top: 5%;
    content: '';
    width: 60%;
    height: 100%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(196, 0, 214, 0.75) 0%,
      rgba(19, 17, 28, 0.75) 100%
    );
    opacity: 0.5;
    filter: blur(rem(75px));
    transform: rotate(-40.25deg);
  }
  &:hover::after {
    opacity: 0.8;
  }
  .imageWrapper {
    border-radius: rem(25px);
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text {
    z-index: 3;
    font-weight: 500;
    font-size: rem(17px);
    line-height: rem(21px);
    @media (max-width: rem(400px)) {
      display: none;
    }
  }
  &.bigger .text {
    font-size: rem(18px);
    line-height: rem(22px);
    @media (max-width: rem(400px)) {
      display: unset;
      font-size: rem(15px);
      line-height: rem(18px);
    }
  }
  .plus {
    z-index: 3;
    width: rem(50px);
    height: rem(50px);
    position: relative;
    @media (max-width: rem(400px)) {
      width: rem(33px);
      height: rem(33px);
    }
    &::after {
      content: '';
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: rem(3px);
    }
    &::before {
      content: '';
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: rem(3px);
    }
  }
  &.bigger .plus {
    width: rem(56px);
    height: rem(56px);
  }

  .inputLabel {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: transparent;
    z-index: 4;
    cursor: pointer;
  }
}
