@import "~styles/mixins";

.feedPage {
  padding-top: rem(40px);
  padding-bottom: rem(60px);
  width: 100%;

  @media (max-width: rem(1150px)) {
    padding-top: rem(25px);
  }
  @media (max-width: rem(840px)) {
    padding-top: 0;
  }

  .content {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: grid;
    grid-template-columns: minmax(auto, rem(350px)) minmax(rem(650px), 1fr);
    gap: rem(80px);

    @media (max-width: 1230px) {
      gap: rem(40px);
    }
    @media (max-width: 1150px) {
      display: flex;
      flex-direction: column;
      gap: rem(40px);
      align-items: center;
    }

    .loaderWrapper {
      width: 100%;
      height: rem(40px);
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: rem(650px);

      .loader {
        width: rem(40px);
        height: rem(40px);
      }
    }
    .topPage {
      // min-height: rem(120px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: rem(35px) rem(92px) rem(30px) rem(92px);
      margin-left: auto;
      margin-right: auto;
      gap: rem(20px);
      width: 100%;
    
      @media (min-width: rem(992px)) {
        max-width: rem(1464px);
      }
    
      @media (max-width: rem(840px)) {
        padding: rem(2px) 0 rem(18px) 0;
      }
    
      .tabsWrapper {
        width: fit-content;
        padding-bottom: rem(8px);
    
        @media (max-width: rem(1200px)) {
          width: 100%;
          overflow-x: auto;
        }
    
        @media (max-width: rem(840px)) {
          padding: 0 rem(13px) rem(8px) rem(13px);
        }
      }
    
      .tabs {
        min-width: rem(650px);
    
        @media (max-width: rem(1200px)) {
          // width: 100%;
        }
      }
      .tabLink {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        white-space: nowrap;
        font-weight: 500;
        font-size: rem(17px);
        line-height: rem(21px);
    
        .linkFill {
          position: absolute;
          opacity: 0;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
    }

    .settings {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: rem(40px);

      @media (max-width: rem(1150px)) {
        gap: rem(10px);
      }
      @media (max-width: rem(400px)) {
        padding-left: rem(15px);
        padding-right: rem(15px);
      }

      .tabsWrapper {
        width: fit-content;

        @media (max-width: rem(1200px)) {
          width: 100%;
          overflow-x: auto;
        }
      }

      .tabs {
        // min-width: rem(550px);
        @media (min-width: rem(1151px)) {
          display: none;
        }
      }
      .tabLink {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        white-space: nowrap;
        font-weight: 500;
        font-size: rem(17px);
        line-height: rem(21px);

        .linkFill {
          position: absolute;
          opacity: 0;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }

      .switch {
        width: 100%;
        padding: rem(5px);
        background-image: linear-gradient(
          94.62deg,
          rgba(196, 0, 214, 0.5) 7.3%,
          rgba(70, 40, 255, 0.5) 99.05%
        );
        border-radius: rem(30px);
        display: flex;
        flex-direction: column;
        gap: rem(18px);
        &Variant {
          border-radius: rem(30px);
          text-align: center;
          padding: rem(19px) rem(0px);
          transition: 0.3s;
          cursor: pointer;

          &.active {
            background: #13111c;
            box-shadow: rem(0px) rem(2px) rem(5px) rgba(0, 0, 0, 0.5);
          }
        }
        @media (max-width: rem(1150px)) {
          display: none;
        }
      }

      .button {
        font-weight: 500;
        font-size: rem(17px);
        line-height: rem(21px);
        border-radius: rem(25px);
        border: 1px solid hsla(248, 100%, 58%, 1);
        padding: rem(8px) 0;
        fill: white;
        display: flex;
        flex-direction: row;
        gap: rem(10px);
        align-items: center;
        justify-content: center;
        @media (max-width: rem(1150px)) {
          display: none;
        }
      }
    }

    .feed {
      display: flex;
      flex-direction: column;
      gap: rem(60px);
    }
  }
}
