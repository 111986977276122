@import '~styles/mixins';

.inputContainer {
  position: relative;
  height: rem(60px);
  &LeftPadded {
    .input {
      padding-left: rem(50px);
    }
  }
  &RightPadded {
    .input {
      padding-right: rem(50px);
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
    border-radius: rem(15px);
  }
  & > svg {
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &:first-child {
      left: rem(15px);
    }
    &:last-child {
      right: rem(15px);
    }
  }
}

.input {
  height: rem(60px);
  position: relative;
  width: 100%;
  z-index: 1;
  color: #ffffff;
  font-size: rem(15px);
  line-height: rem(18px);
  font-weight: 500;
  background-color: var(--background-color);
  border: none;
  padding: rem(15px);
  border-radius: rem(15px);
  outline: rem(1px) solid transparent;
  transition: outline-color 0.3s ease;
  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  &:focus-visible {
    outline: rem(1px) solid #c400d6;
  }
}
