@import '~styles/mixins';
@import '~styles/variables';

.layout {
  height: var(--height, 100vh);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.contentWrapper {
  width: 100%;
  height: calc(var(--height, 100vh) - $headerHeightDesktop);
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: rem(1150px)) {
    height: calc(var(--height, 100vh) - $headerHeightDesktop - $bottomHeight);
  }
  @media (max-width: rem(840px)) {
    height: calc(var(--height, 100vh) - $headerHeightTablet);
  }
}
