@import '~styles/mixins';

.styles {
    padding-top: rem(60px);
    display: flex;
    flex-direction: column;
    gap: rem(20px);

    &Title {
      font-weight: 600;
      font-size: rem(20px);
      line-height: rem(24px);
      z-index: 2;
    }
    &Grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(rem(110px), rem(305px)));
      grid-template-rows: auto;
      justify-content: center;
      column-gap: rem(20px);
      row-gap: rem(15px);
      @media (max-width: rem(575px)) {
        column-gap: rem(10px);
        row-gap: rem(10px);
        grid-template-rows: unset;
        grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
      }
    }
    &Variant {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      min-height: rem(305px);
      gap: rem(14px);
      position: relative;
      transition: 0.3s;
      z-index: 1;
      border-radius: rem(25px);

      @media (max-width: rem(570px)) {
        min-height: rem(255px);
      }
      @media (max-width: rem(430px)) {
        min-height: rem(205px);
      }

      &.active {
        box-shadow: rem(-1px) rem(0px) rem(40px) rem(-10px) #c400d6;
        -webkit-box-shadow: rem(-1px) rem(0px) rem(40px) rem(-10px) #c400d6;
        -moz-box-shadow: rem(-1px) rem(0px) rem(40px) rem(-10px) #c400d6;
      }

      &::after {
        z-index: 1;
        position: absolute;
        content: '';
        left: 0;
        width: 100%;
        height: 100%;
        top: 0;
        border-radius: rem(25px);
        border: 1px solid rgba(255, 255, 255, 0.25);
      }

      .picture {
        z-index: 2;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: rem(25px);
        max-height: rem(250px);

        @media (max-width: rem(570px)) {
          max-height: rem(200px);
        }
        @media (max-width: rem(430px)) {
          max-height: rem(150px);
        }
      }
      .text {
        padding-left: rem(25px);
        font-weight: 600;
        font-size: rem(17px);
        line-height: rem(21px);
      }
    }
  }