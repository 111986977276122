@import '~styles/mixins';

.processingStage {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: rem(40px);
}

.loaderWrapper {
  width: rem(300px);
  height: rem(300px);

  @media (max-width: rem(841px)) {
    width: rem(250px);
    height: rem(250px);
  }
}

.bannerText {
  z-index: 2;
  color: hsla(0, 0%, 100%, 0.5);
  font-weight: 500;
  font-size: rem(20px);
  line-height: rem(24px);
}
