@import '~styles/mixins';

.input {
  min-width: rem(350px);
  @media (max-width: rem(575px)) {
    min-width: auto;
    width: 100%;
  }
}

.bottom {
  z-index: 1;
  padding-left: rem(15px);
  padding-right: rem(15px);
  @media (max-width: rem(575px)) {
    padding-left: 0;
    padding-right: 0;
  }
}

.topPage {
  // min-height: rem(120px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: rem(35px) rem(92px) rem(30px) rem(92px);
  margin-left: auto;
  margin-right: auto;
  gap: rem(20px);
  width: 100%;

  @media (min-width: rem(992px)) {
    max-width: rem(1464px);
  }

  @media (max-width: rem(840px)) {
    padding: rem(2px) 0 rem(18px) 0;
  }

  .tabsWrapper {
    width: fit-content;
    padding-bottom: rem(8px);

    @media (max-width: rem(1200px)) {
      width: 100%;
      overflow-x: auto;
    }

    @media (max-width: rem(840px)) {
      padding: 0 rem(13px) rem(8px) rem(13px);
    }
  }

  .tabs {
    min-width: rem(650px);

    @media (max-width: rem(1200px)) {
      // width: 100%;
    }
  }
  .tabLink {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    font-weight: 500;
    font-size: rem(17px);
    line-height: rem(21px);

    .linkFill {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}

.imageInput {
  border-radius: rem(25px);
  height: rem(50px);
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  font-weight: 500;
  font-size: rem(17px);
  line-height: rem(21px);
  @media (max-width: rem(1200px)) {
    display: none;
  }

  span,
  svg {
    z-index: 2;
  }

  &::before {
    content: '';
    position: absolute;
    background-color: var(--background-color);
    left: rem(1px);
    top: rem(1px);
    width: calc(100% - rem(2px));
    height: calc(100% - rem(2px));
    border-radius: rem(25px);
    z-index: 1;
  }
}
