@import '~styles/mixins';

.previewStage {
  width: 100%;
  height: 100%;
  padding-bottom: rem(20px);

  @media (max-width: rem(1150px)) {
    padding-bottom: rem(120px);
  }
  @media (max-width: rem(972px)) {
    height: unset;
    min-height: 100%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: rem(1360px);
  margin: 0 auto;
  padding: 0 rem(40px);
}

.navigationPanel {
  padding: rem(24px) 0 rem(26px) 0;

  .goBackButton {
    width: rem(50px);
    height: rem(50px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: rem(20px);
  }
}

.content {
  display: grid;
  // flex-direction: row;
  grid-template-columns: 1fr minmax(rem(384px), 40%);
  align-items: start;
  justify-items: center;
  gap: rem(40px);
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: rem(972px)) {
    grid-template-columns: 1fr;
    grid-template-rows: minmax(auto, rem(350px)) auto;
  }

  .controlPanel {
    background-color: hsla(251, 27%, 11%, 1);
    border-radius: rem(30px);
    width: 100%;
    padding: rem(53px) rem(21px) rem(51px) rem(21px);
    display: flex;
    flex-direction: column;
    max-width: rem(490px);

    @media (max-width: rem(972px)) {
      padding: rem(30px) rem(20px);
    }

    &Title {
      text-align: center;
      font-weight: 500;
      font-size: rem(20px);
      line-height: rem(24px);
    }
  }
}

.button {
  // min-width: rem(350px);
  margin-top: rem(40px);
  border-radius: rem(30px);
  @media (max-width: rem(575px)) {
    min-width: auto;
    width: 100%;
  }
}

.imageWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: rem(25px);
  overflow: hidden;

  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
  }
}

.switch {
  margin-top: rem(79px);
  position: relative;
  display: flex;
  gap: rem(1px);
  background: linear-gradient(
    95deg,
    rgba(#c400d6, 0.25) 0%,
    rgba(#4628ff, 0.25) 100%
  );
  border-radius: rem(30px);
  &:before {
    content: '';
    position: absolute;
    top: rem(1px);
    bottom: rem(1px);
    left: rem(1px);
    right: rem(1px);
    background-color: var(--background-color);
    z-index: 0;
    border-radius: rem(30px);
  }

  @media (max-width: rem(972px)) {
    margin-top: rem(20px);
  }

  &Button {
    display: block;
    cursor: pointer;
    width: 50%;
  }
  &Input {
    &:checked ~ .switchBody {
      &::before {
        opacity: 1;
      }
    }
  }
  &Body {
    position: relative;
    display: block;
    padding: rem(10px);
    text-align: center;
    border-radius: rem(30px);
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
      border-radius: rem(30px);
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    & > * {
      position: relative;
      z-index: 1;
    }
  }
  &Filler {
    position: absolute;
    top: rem(2px);
    bottom: rem(2px);
    left: rem(2px);
    right: rem(2px);
    background-color: var(--background-color);
    border-radius: rem(30px);
    z-index: 0;
  }
  &Heading {
    display: block;
    font-weight: 500;
    font-size: rem(17px);
    line-height: rem(21px);
  }
  &Text {
    display: block;
    font-weight: 500;
    font-size: rem(15px);
    line-height: rem(18px);
    opacity: 0.75;
  }
}
