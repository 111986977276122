.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  user-select: none;
}

.resetButton {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #ffffff;
  transition: background-color 0.3s ease, opacity 0.3s ease;
  &:disabled {
    opacity: 0;
    pointer-events: none;
  }
}

.badge {
  position: absolute;
  top: 15px;
  padding: 9px 20px;
  border-radius: 20px;
  background: #ffffff;
  @media (max-width: 767px) {
    padding: 7px 15px;
  }
  &Left {
    left: 20px;
  }
  &Right {
    right: 20px;
  }
  &Text {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: var(--background-color);
    background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 18px;
    }
  }
}
