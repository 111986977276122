@import '~styles/mixins';

.heading {
  font-weight: 600;
  font-size: rem(20px);
  line-height: rem(24px);
}

.photoSelection {
    display: flex;
    flex-direction: column;
    gap: rem(20px);
    .historyList {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, rem(240px));
      grid-template-rows: rem(240px);
      gap: rem(20px);
      justify-content: center;

      @media (max-width: rem(534px)) {
        grid-template-columns: repeat(auto-fill, rem(180px));
        grid-template-rows: rem(180px);
      }
      @media (max-width: rem(430px)) {
        grid-template-columns: repeat(auto-fill, rem(140px));
        grid-template-rows: rem(140px);
        gap: rem(10px);
      }

      .wrapper {
        z-index: 2;
        position: relative;
        overflow: hidden;
        border-radius: rem(25px);
        padding-top: 100%;
        transition: 0.3s;
        @media (max-width: rem(575px)) {
          border-radius: rem(15px);
        }

        &.active {
          box-shadow: rem(-1px) rem(0px) rem(40px) rem(-10px) #c400d6;
          -webkit-box-shadow: rem(-1px) rem(0px) rem(40px) rem(-10px) #c400d6;
          -moz-box-shadow: rem(-1px) rem(0px) rem(40px) rem(-10px) #c400d6;
        }
      }

      .image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .link {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }