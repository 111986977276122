@import '../../styles/_mixins.scss';

.variationFinishPage {
  flex-grow: 1;
  // padding-bottom: var(--bottom-padding);
  height: 100%;
}

.container {
  max-width: rem(1360px);
  margin: 0 auto;
  padding: 0 rem(40px);
  @media (max-width: rem(1150px)) {
    padding-bottom: rem(10px);
  }
  @media (max-width: rem(1100px)) {
    padding-left: rem(22px);
    padding-right: rem(22px);
  }
}
