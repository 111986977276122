@import "~styles/mixins";

.wrapper {
  height: rem(50px);
  min-height: rem(50px);
  display: flex;
  gap: rem(10px);
  padding: rem(5px);
  background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
  border-radius: rem(25px);
  min-width: 0;
  position: relative;
  @media (max-width: rem(575px)) {
    // max-width: rem(300px);
  }
  .currentWrapper {
    position: absolute;
    top: rem(5px);
    height: calc(100% - rem(10px));
    left: rem(5px);
    width: calc(100% - rem(10px));

    .current {
      top: rem(0px);
      border-radius: rem(30px);
      z-index: 1;
      transition: 0.3s;
      position: absolute;
      background-color: var(--background-color);
      height: 100%;
    }
  }
  .navigationButton {
    z-index: 2;
    position: relative;
    width: 100%;
    min-width: rem(223px);
    height: 100%;
    text-align: center;
    font-size: rem(15px);
    line-height: rem(18px);
    font-weight: 500;
    border-radius: rem(15px);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    @media (max-width: rem(1200px)) {
      min-width: unset;
    }
    @media (max-width: rem(575px)) {
      font-size: rem(12px);
      line-height: rem(16px);
    }
    &Active {
      background-color: var(--background-color);
      box-shadow: 0 rem(4px) rem(4px) rgba(0, 0, 0, 0.25);
    }
  }
}
