@import '~styles/mixins';

.finishStage {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: rem(20px);
}

.navigationPanel {
  padding: rem(24px) 0 rem(26px) 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .goBackButton {
    width: rem(50px);
    height: rem(50px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: rem(20px);

    @media (max-width: rem(1070px)) {
      width: rem(40px);
      height: rem(40px);
      border-radius: rem(15px);
    }
  }

  @media (max-width: rem(1070px)) {
    padding: rem(12px) 0 rem(12px) 0;
  }
}

.content {
  display: grid;
  // flex-direction: row;
  grid-template-columns: 1fr 40%;
  align-items: start;
  gap: rem(40px);
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: rem(1150px)) {
    grid-template-columns: 1fr 40%;
    gap: rem(20px);
  }

  @media (max-width: rem(1070px)) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
  }

  .controlPanel {
    background-color: hsla(251, 27%, 11%, 1);
    border-radius: rem(30px);
    width: 100%;
    padding: rem(53px) rem(21px) rem(51px) rem(21px);
    display: flex;
    flex-direction: column;

    &Title {
      text-align: center;
      font-weight: 500;
      font-size: rem(20px);
      line-height: rem(24px);
    }

    @media (max-width: rem(1130px)) {
      padding: rem(33px) rem(15px) rem(31px) rem(15px);
    }

    @media (max-width: rem(1070px)) {
      background-color: transparent;
      padding: 0;
    }
  }
}

.rating {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: rem(15px);
  &Text {
    font-size: rem(20px);
    font-weight: 400;
    line-height: rem(24px);
    padding-top: rem(6px);
    padding-bottom: rem(6px);
    @media (max-width: rem(1130px)) {
      font-size: rem(18px);
    }
    @media (max-width: rem(767px)) {
      font-size: rem(15px);
      line-height: rem(18px);
      padding-top: rem(9px);
      padding-bottom: rem(9px);
    }
  }
}

.controls {
  margin-top: rem(20px);
  flex-wrap: nowrap;
  @media (max-width: rem(767px)) {
    margin-top: rem(15px);
  }
  @media (max-width: rem(400px)) {
    flex-wrap: wrap;
  }

  &Button {
    width: 100%;
    height: 100%;
    max-width: rem(350px);

    @media (max-width: rem(1130px)) {
      padding: rem(13px);
      max-width: rem(300px);
      & > span {
        font-size: rem(16px);
      }
    }
  }
}
