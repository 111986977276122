@import '~styles/mixins';

.postPage {
  width: 100%;
  padding-top: rem(40px);
  padding-bottom: rem(100px);
//   height: 100%;

  .goBackButton {
    width: rem(50px);
    height: rem(50px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: rem(20px);
  }

  .content{
    display: flex;
    justify-content: center;
    height: 100%;

    .postWrapper{
        width: 100%;
        height: 100%;
        max-width: rem(680px);

        .postImage{
            max-height: unset;
        }
    }
  }
}
