@import '~styles/mixins';

.advancedSettingsContainer {
  position: relative;
  height: rem(60px);
  margin-top: rem(20px);
  &::before {
    // transition: 1s all;
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
    border-radius: rem(15px);
  }
}

.advancedSettings {
  height: rem(60px);
  position: relative;
  width: 100%;
  z-index: 1;
  color: #ffffff;
  font-size: rem(16px);
  line-height: rem(21px);
  font-weight: 600;
  background-color: var(--background-color);
  border: none;
  padding: rem(15px);
  border-radius: rem(15px);
  outline: rem(1px) solid transparent;
  transition: outline-color 0.3s ease;
}

.settings {
  display: flex;
  flex-direction: column;
  gap: 50px;
}