.loader {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;

  &Line {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;

    .loaderCircle {
      position: absolute;
      width: 20%;
      height: 20%;
      background: #ffffff;
      border-radius: 50%;
      animation: loading 1.6s linear infinite;
    }

    &:nth-child(1) {
      transform: translate(-50%, -50%) rotate(90deg);
      :nth-child(1) {
        animation-delay: 0s;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      :nth-child(2) {
        animation-delay: -0.8s;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &:nth-child(2) {
      transform: translate(-50%, -50%) rotate(135deg);
      :nth-child(1) {
        animation-delay: -0.2s;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      :nth-child(2) {
        animation-delay: -1s;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &:nth-child(3) {
      transform: translate(-50%, -50%) rotate(180deg);
      :nth-child(1) {
        animation-delay: -0.4s;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      :nth-child(2) {
        animation-delay: -1.2s;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &:nth-child(4) {
      transform: translate(-50%, -50%) rotate(225deg);
      :nth-child(1) {
        animation-delay: -0.6s;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      :nth-child(2) {
        animation-delay: -1.4s;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

@keyframes loading {
  0%,
  20%,
  80%,
  100% {
    transform: translateY(-50%) scale(1);
  }
  50% {
    transform: translateY(-50%) scale(0.25);
  }
}
