
@import '~styles/mixins';

.heading {
  font-weight: 600;
  font-size: rem(20px);
  line-height: rem(24px);
}

.powerSelection {
    display: flex;
    flex-direction: column;
    gap: rem(20px);

    .rangeWrapper {
      position: relative;
      @media (max-width: 840px) {
          margin: 0px 10px;
      }
    }
  }