@import '~styles/mixins';

.heading {
  font-weight: 600;
  font-size: rem(20px);
  line-height: rem(24px);
}

.input {
  margin-top: rem(20px);
  // max-width: 95%;
}

.topPage {
  // min-height: rem(120px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: rem(35px) rem(92px) rem(30px) rem(92px);
  margin-left: auto;
  margin-right: auto;
  gap: rem(20px);
  width: 100%;

  @media (min-width: rem(992px)) {
    max-width: rem(1464px);
  }

  @media (max-width: rem(840px)) {
    padding: rem(2px) 0 rem(18px) 0;
  }

  .tabsWrapper {
    width: fit-content;
    padding-bottom: rem(8px);

    @media (max-width: rem(1200px)) {
      width: 100%;
      overflow-x: auto;
    }

    @media (max-width: rem(840px)) {
      padding: 0 rem(13px) rem(8px) rem(13px);
    }
  }

  .tabs {
    min-width: rem(650px);

    @media (max-width: rem(1200px)) {
      // width: 100%;
    }
  }
  .tabLink {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    font-weight: 500;
    font-size: rem(17px);
    line-height: rem(21px);

    .linkFill {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}
