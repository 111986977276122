@import '~styles/mixins';

.socials {
  display: flex;
  flex-direction: column;
  gap: rem(10px);
  &Button {
    background: #ffffff;
    span {
      color: #1b1b1b;
      font-size: rem(15px);
      font-weight: 500;
      line-height: rem(18px);
      margin-left: auto;
      margin-right: auto;
    }
  }
}
