@import '~styles/mixins';

.dropdownWrapper {
  position: relative;
  z-index: 5;
  user-select: none;
  .dropdown {
    font-weight: 600;
    font-size: rem(15px);
    line-height: rem(18px);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: center;
    width: rem(202px);
    min-height: rem(40px);
    border-radius: rem(25px);
    gap: rem(0px);
    border: 1px solid hsla(295, 100%, 42%, 1);
    background-color: var(--background-color);
    z-index: 2;
    &.active {
      border: none;
      background-image: linear-gradient(
        94.62deg,
        hsla(295, 100%, 42%, 1) 7.3%,
        hsla(248, 100%, 58%, 1) 99.05%
      );
      background-size: 170% 170%;
      animation: gradientTransfusionBG 10s linear infinite;
    }
    @media (max-width: rem(1310px)) {
      width: rem(190px);
      font-size: rem(14px);
    }
  }
  .icon {
    transition: 0.5s;
    width: rem(20px);
    height: rem(20px);
  }
  .menu {
    opacity: 0;
    border: 1px solid hsla(295, 100%, 42%, 1);
    position: absolute;
    z-index: 1;
    left: rem(0px);
    top: rem(0px);
    background-color: var(--background-color);
    border-radius: rem(22px);
    width: rem(279px);
    list-style: none;
    transition: 0.7s;
    overflow-y: hidden;
    overflow-x: auto;
    height: auto;
    pointer-events: none;

    .header {
      height: rem(38px);
      width: rem(200px);
      font-weight: 600;
      font-size: rem(15px);
      line-height: rem(18px);
      gap: rem(0px);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @media (max-width: rem(1310px)) {
        width: rem(190px);
        font-size: rem(14px);
      }
    }

    .linksWrapper {
      padding: 0 rem(22.5px) rem(29px) rem(22.5px);
      .links {
        padding-top: rem(30px);
        border-top: rem(1px) solid hsla(295, 100%, 42%, 0.5);
        display: flex;
        flex-direction: column;
        gap: rem(30px);

        .link {
          display: flex;
          flex-direction: column;
          gap: rem(8px);
          transition: 0.3s;
          padding: rem(7px);
          &:hover:not(.active) {
            color: hsla(248, 100%, 78%, 1);
          }
          &.active {
            color: hsla(295, 100%, 78%, 1);
          }
          &:hover.active {
            color: hsla(295, 100%, 85%, 1);
          }

          .linkTitle {
            font-weight: 500;
            font-size: rem(15px);
            line-height: rem(18px);
          }

          .linkDescription {
            font-weight: 500;
            font-size: rem(12px);
            line-height: rem(12px);
          }
        }
      }
    }
  }
  &:hover {
    z-index: 10;

    .menu {
      opacity: 1;
      z-index: 12;
      pointer-events: all;
    }

    .icon {
      transform: rotate(-180deg);
    }
  }
}
