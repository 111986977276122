@import '~styles/mixins';

.post {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: rem(650px);
  animation-name: appearing;
  animation-duration: 2s;

  @keyframes appearing {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .postImgWrapperTrending {
    position: relative;
    overflow: hidden;
    // min-height: rem(350px);
    // height: rem(600px);
    border-radius: rem(25px);
    // aspect-ratio: 10 / 8;
    aspect-ratio: 1;
  
    @media (max-width: rem(768px)) {
      // height: rem(500px);
      border-radius: rem(15px);
      // min-height: rem(200px);
    }
  
    @media (max-width: rem(390px)) {
      // height: rem(400px);
      border-radius: rem(15px);
      // min-height: rem(200px);
      // aspect-ratio: 10 / 9;
    }

    @media (max-width: rem(320px)) {
      // height: rem(350px);
      border-radius: rem(15px);
      // min-height: rem(300px);
    }
  
    .postImg {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .postImgWrapper {
    position: relative;
    overflow: hidden;
    // min-height: rem(350px);
    // height: rem(600px);
    border-radius: rem(25px);
    // aspect-ratio: 10 / 8;
    // aspect-ratio: 1;
  
    @media (max-width: rem(768px)) {
      // height: rem(500px);
      border-radius: rem(15px);
      // min-height: rem(200px);
    }
  
    @media (max-width: rem(390px)) {
      // height: rem(400px);
      border-radius: rem(15px);
      // min-height: rem(200px);
      // aspect-ratio: 10 / 9;
    }

    @media (max-width: rem(320px)) {
      // height: rem(350px);
      border-radius: rem(15px);
      // min-height: rem(300px);
    }
  
    .postImg {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .link {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .linkForVideo {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 90%;
  
    @media (max-width: rem(600px)) {
      height: 85%;
    }
  
    @media (max-width: rem(370px)) {
      height: 80%;
    }
  }

  &Top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: rem(20px);
    padding-bottom: rem(13px);
    
    @media (max-width: rem(950px)) {
      margin-top: rem(15px);
    }
  }

  &User {
    display: flex;
    flex-direction: row;
    gap: rem(20px);
    position: relative;

    &:hover {
      color: hsla(248, 100%, 78%, 1);
    }
    
    @media (max-width: rem(400px)) {
      gap: rem(10px);
    }

    .avatarWrapper {
      overflow: hidden;
      width: rem(65px);
      height: rem(65px);
      border-radius: rem(20px);
      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-shadow: rem(0px) rem(2px) rem(7px) rgba(0, 0, 0, 0.5);
      }

      @media (max-width: rem(400px)) {
        width: rem(40px);
        height: rem(40px);
        border-radius: rem(15px);
      }
    }

    .nicknameWrapper {
      display: flex;
      flex-direction: column;
      gap: rem(5px);
      padding-top: rem(4px);
      .nickname {
        font-weight: 500;
        font-size: rem(20px);
        line-height: rem(24px);
        transition: 0.3s;
        @media (max-width: rem(400px)) {
          font-size: rem(15px);
          line-height: rem(18px);
        }
      }
      .date {
        font-weight: 400;
        font-size: rem(15px);
        line-height: rem(18px);
        color: hsla(240, 6%, 83%, 1);
        @media (max-width: rem(400px)) {
          font-size: rem(10px);
          line-height: rem(12px);
        }
      }
    }
  }

  &FeedBack {
    padding: rem(13px) rem(20px) 0 rem(20px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .postLikes {
      display: flex;
      flex-direction: row;
      gap: rem(10px);
      align-items: center;
      cursor: pointer;

      &Icon {
        fill: white;
        position: relative;
        .favoredIcon {
          opacity: 0;
          pointer-events: none;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: 0.3s;
          
          &.favored {
            opacity: 1;
            pointer-events: all;
          }
        }
      }

      &Count {
        font-weight: 500;
        font-size: rem(15px);
        line-height: rem(18px);

        @media (max-width: rem(400px)) {
          font-size: rem(12px);
          line-height: rem(15px);
        }
      }
    }

    .postViews {
      font-weight: 400;
      font-size: rem(15px);
      line-height: rem(18px);
      color: hsla(240, 6%, 83%, 1);
      @media (max-width: rem(400px)) {
        font-size: rem(10px);
        line-height: rem(12px);
      }
    }
  }

  &Bottom {
    padding: rem(20px) rem(20px) 0 rem(20px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .text {
      font-weight: 500;
      font-size: rem(15px);
      line-height: 150%;
      max-width: 90%;

      &Style {
        position: relative;
        border: 1px solid hsla(295, 100%, 42%, 1);
        border-radius: rem(20px);
        padding: rem(2px);
        // &::after{
        //     position: absolute;
        //     border-radius: rem(20px);
        //     content: "";
        //     width: calc(100% + rem(4px));
        //     height: rem(25px);
        //     left: 50%;
        //     top: 50%;
        //     transform: translate(-50%, -50%);
        //     border: rem(1px) solid hsla(295, 100%, 42%, 1);
        // }
      }

      @media (max-width: rem(400px)) {
        font-size: rem(12px);
        line-height: rem(15px);
      }
    }
    .postCopy {
      width: rem(40px);
      height: rem(40px);
      border-radius: 50%;
      fill: white;

      @media (max-width: rem(400px)) {
        width: rem(30px);
        height: rem(30px);
      }
    }
  }
}

.admin{
  display: flex;
  flex-direction: column;
  gap: rem(10px);

  .delete{
    padding: rem(10px);
    border: 1px solid hsla(295, 100%, 42%, 1);
    transition: 0.2s;
    border-radius: rem(15px);
    font-size: rem(16px);

    &:hover{
      background-color: hsla(295, 100%, 42%, 1);
    }
  }
}