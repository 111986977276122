.main {
  flex-grow: 1;
  // padding-bottom: var(--bottom-padding);
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}
