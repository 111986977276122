@import '~styles/mixins';

.history {
  // position: absolute;
  // z-index: 0;
  width: 100%;
  // height: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(rem(110px), rem(240px)));
  grid-template-rows: minmax(auto, rem(240px));
  justify-content: center;
  column-gap: rem(20px);
  row-gap: rem(15px);
  @media (max-width: rem(575px)) {
    column-gap: rem(10px);
    row-gap: rem(10px);
    grid-template-rows: unset;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  }
}

.wrapper {
  z-index: 2;
  position: relative;
  overflow: hidden;
  border-radius: rem(25px);
  padding-top: 100%;
  animation-name: appearing;
  animation-duration: 2s;

  @keyframes appearing {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: rem(575px)) {
    border-radius: rem(15px);
  }
}

.image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.link {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

// .filler {
//   height: calc(var(--bottom-height) + rem(15px));
// }

.loader {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.loadMoreButton {
  margin-top: rem(15px);
  padding: rem(10px) rem(20px);
  margin-left: auto;
  margin-right: auto;
  background: transparent;
  span {
    font-size: rem(16px);
    line-height: rem(20px);
  }
  @media (max-width: rem(575px)) {
    margin-top: rem(10px);
    span {
      font-size: rem(14px);
      line-height: rem(18px);
    }
  }
}

.addImageWrapper {
  margin: rem(178px) auto 0 auto;
  max-width: rem(345px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: rem(40px);
  text-align: center;
  margin-top: calc(var(--height, 100vh) / 2 - rem(125px) - rem(123px) - rem(60px));

  .addImageInfo {
    z-index: 2;
    font-weight: 500;
    font-size: rem(15px);
    line-height: rem(18px);
    color: hsla(240, 6%, 83%, 1);

    @media (max-width: rem(400px)) {
      font-size: rem(12px);
    }
  }

  @media (max-width: rem(800px)) {
    gap: rem(30px);
    margin-top: calc(var(--height, 100vh) / 2 - rem(100px) - rem(100px) - rem(60px));
  }

  @media (max-width: rem(400px)) {
    gap: rem(20px);
    margin-top: calc(var(--height, 100vh) / 2 - rem(100px) - rem(82.5px) - rem(60px));
  }

  .addImage {
    z-index: 1;
    border-radius: rem(50px);
    width: rem(250px);
    height: rem(250px);

    @media (max-width: rem(800px)) {
      width: rem(200px);
      height: rem(200px);
    }

    @media (max-width: rem(400px)) {
      width: rem(175px);
      height: rem(175px);
    }

    &::before {
      left: 30%;
      top: 50%;
      content: '';
      width: 100%;
      height: 150%;
      background-image: radial-gradient(
        66.38% 66.38% at 41.32% 33.62%,
        rgba(70, 40, 255, 0.75) 0%,
        rgba(19, 17, 28, 0.75) 100%
      );
      opacity: 0.5;
      filter: blur(rem(100px));
      transform: translateY(-50%) rotate(26.75deg);
    }
    &::after {
      left: -30%;
      top: 50%;
      transform: translateY(-50%) rotate(-40.25deg);
      content: '';
      width: 100%;
      height: 150%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(196, 0, 214, 0.75) 0%,
        rgba(19, 17, 28, 0.75) 100%
      );
      opacity: 0.5;
      filter: blur(rem(100px));
    }
    &:hover::before {
      opacity: 0.6;
    }
    &:hover::after {
      opacity: 0.6;
    }
  }
}

.loaderWrapper {
  position: relative;
  width: 100%;
  height: rem(40px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: rem(40px);

  .loader {
    width: rem(40px);
    height: rem(40px);
  }
}
