@import '~styles/mixins';

.settings {
  display: flex;
  flex-direction: column;
  gap: rem(50px);
  &Item {
  }
}

.goBackButton {
  width: rem(50px);
  height: rem(50px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: rem(20px);
  margin-left: auto;
}

.list {
  margin-top: rem(20px);
  list-style: none;
  border-radius: rem(15px);
  background-color: #191528;
  padding-top: rem(15px);
  padding-bottom: rem(15px);
  display: flex;
  flex-direction: column;
  gap: rem(10px);
  &Title {
    font-size: rem(20px);
    font-weight: 600;
    line-height: rem(24px);
  }
  &Item {
  }
}

.button {
  width: 100%;
  padding: rem(15px) rem(25px);
  display: flex;
  align-items: center;
  gap: rem(15px);
  color: rgba(#ffffff, 0.75);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(#c400d6, 0.075);
  }
  &Text {
    font-size: rem(15px);
    font-weight: 500;
    line-height: rem(24px);
  }
  &StartIcon {
    fill: currentColor;
  }
  &EndIcon {
    margin-left: auto;
    fill: currentColor;
  }
}

.text {
  width: 100%;
  padding: rem(15px) rem(25px);
  display: flex;
  align-items: center;
  gap: rem(15px);
  color: #ffffff;
}

.copyright {
  text-align: center;
  color: rgba(#ffffff, 0.5);
  font-size: rem(15px);
  font-weight: 500;
  line-height: rem(18px);
}
