@import '~styles/mixins';

.list {
  display: flex;
  flex-direction: column;
  border-radius: rem(15px);
  background-color: #191528;
  padding-top: rem(15px);
  padding-bottom: rem(15px);
  gap: rem(10px);
}

.label {
  cursor: pointer;
  width: 100%;
  padding: rem(15px) rem(25px);
  display: flex;
  align-items: center;
  gap: rem(15px);
  color: rgba(#ffffff, 0.75);
  font-size: rem(15px);
  font-weight: 500;
  line-height: rem(24px);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(#c400d6, 0.075);
  }
}

.checkIcon {
  fill: currentColor;
  margin-left: auto;
}
