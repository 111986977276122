@import '~styles/mixins';

.loader {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  gap: rem(20px);

  .loaderIconWrapper {
    z-index: 2;
    width: rem(50px);
    height: rem(50px);
  }

  .blueShadow {
    position: absolute;
    left: 30%;
    top: 60%;
    width: 100%;
    height: 150%;
    background-image: radial-gradient(
      66.38% 66.38% at 41.32% 33.62%,
      rgba(70, 40, 255, 0.75) 0%,
      rgba(19, 17, 28, 0.75) 100%
    );
    opacity: 0.4;
    filter: blur(rem(100px));
    transform: translateY(-50%) rotate(26.75deg);
    transition: 0.3s;
  }
  .purpleShadow {
    position: absolute;
    left: -30%;
    top: 60%;
    transform: translateY(-50%) rotate(-40.25deg);
    width: 100%;
    height: 150%;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(196, 0, 214, 0.75) 0%,
      rgba(19, 17, 28, 0.75) 100%
    );
    opacity: 0.4;
    filter: blur(rem(100px));
    transition: 0.3s;
  }
  &:hover .blueShadow {
    opacity: 0.6;
  }
  &:hover .purpleShadow {
    opacity: 0.6;
  }
  &::before {
    content: '';
    width: calc(100% - rem(6px));
    height: calc(100% - rem(6px));
    left: rem(3px);
    top: rem(3px);
    position: absolute;
    border-radius: 50%;
    background-color: var(--background-color);
  }
  &Text {
    z-index: 2;
    font-weight: 600;
    font-size: rem(20px);
    line-height: rem(24px);
    @media (max-width: rem(767px)) {
      font-size: rem(15px);
      line-height: rem(18px);
      margin-left: rem(10px);
    }
  }
}

// .banner {
//   position: relative;
//   text-align: center;
//   min-width: rem(350px);
//   border-radius: rem(15px);
//   margin-top: rem(25px);
//   padding: rem(15px) rem(25px);
//   background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
//   @media (max-width: rem(767px)) {
//     margin-top: rem(20px);
//   }
//   @media (max-width: rem(575px)) {
//     min-width: auto;
//     width: 100%;
//   }
//   &::before {
//     content: '';
//     position: absolute;
//     left: rem(2px);
//     right: rem(2px);
//     top: rem(2px);
//     bottom: rem(2px);
//     border-radius: rem(15px);
//     background-color: var(--background-color);
//   }
//   &Text {
//     position: relative;
//     font-size: rem(20px);
//     font-weight: 600;
//     line-height: rem(24px);
//     @media (max-width: rem(767px)) {
//       font-size: rem(15px);
//       line-height: rem(18px);
//     }
//   }
// }
