@import '~styles/mixins';
@import '~styles/variables';

.header {
  height: $headerHeightDesktop;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-width: rem(1px);
  border-bottom-style: solid;
  border-image: linear-gradient(
      45deg,
      hsla(295, 100%, 42%, 1),
      hsla(248, 100%, 58%, 1)
    )
    1;
  animation: gradientTransfusionBorder 10s linear infinite;
  @media (max-width: rem(840px)) {
    height: rem(85px);
    border: unset;
  }
}

.link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

@keyframes gradientTransfusionBorder {
  0% {
    border-image: linear-gradient(
        45deg,
        hsla(295, 100%, 42%, 1),
        hsla(248, 100%, 58%, 1)
      )
      1;
  }
  33% {
    border-image: linear-gradient(
        170deg,
        hsla(295, 100%, 42%, 1),
        hsla(248, 100%, 58%, 1)
      )
      1;
  }
  66% {
    border-image: linear-gradient(
        270deg,
        hsla(295, 100%, 42%, 1),
        hsla(248, 100%, 58%, 1)
      )
      1;
  }
}

@keyframes gradientTransfusionBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.container {
  width: 100%;
  max-width: rem(1648px);
  padding: 0 rem(24px);
  // padding: 0 rem(92px);
  // max-width: rem(1784px);

  @media (max-width: rem(800px)) {
    padding: 0 rem(25px);
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: rem(30px);
  // @media (max-width: rem(1270px)) {
  //   gap: rem(20px);
  // }

  .leftPart {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: rem(764px);
    gap: rem(20px);
  }
  .rightPart {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: rem(597px);
    gap: rem(10px);

    // @media (max-width: rem(1440px)) {
    @media (max-width: rem(1150px)) {
      flex-direction: row-reverse;
    }
  }

  .nav {
    width: 100%;
    max-width: rem(444px);
    // @media (max-width: rem(1440px)) { // 1100 1150
    @media (max-width: rem(1270px)) {
      display: none;
    }
  }
  .nav__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    width: 100%;
    max-width: rem(444px);
    gap: rem(15px);
    flex-wrap: nowrap;
  }

  .nav__elem {
    white-space: nowrap;
    transition: 0.3s;
    font-size: rem(17px);
    font-weight: 500;
    font-family: Montserrat;
    @media (min-width: rem(1100px)) {
      &:nth-child(odd):hover {
        color: hsla(248, 100%, 78%, 1);
        transform: translateY(rem(-2px));
      }
      &:nth-child(even):hover {
        color: hsla(295, 100%, 78%, 1);
        transform: translateY(rem(-2px));
      }
    }
    @media (max-width: rem(1320px)) {
      font-size: rem(16px);
    }
  }

  .dropdowns {
    display: flex;
    flex-direction: row;
    gap: rem(20px);
    // @media (max-width: rem(1270px)) {
    //   gap: rem(10px);
    // }
    // @media (max-width: rem(1440px)) {
    @media (max-width: rem(1150px)) {
      display: none;
    }
  }
  .user {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: rem(10px);
    position: relative;

    .link {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .nickname {
      font-weight: 500;
      font-size: rem(17px);
      line-height: rem(21px);
      transition: 0.3s;

      @media (max-width: rem(500px)) {
        display: none;
      }
    }
    &:hover {
      color: hsla(248, 100%, 78%, 1);
    }
    .avatarWrapper {
      width: rem(40px);
      height: rem(40px);
      box-shadow: rem(0px) rem(2px) rem(7px) rgba(0, 0, 0, 0.5);
      border-radius: rem(15px);
      overflow: hidden;
      .avatar {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'logo navigation controls';
  column-gap: rem(10px);
  row-gap: rem(25px);
  min-width: 0;
  @media (max-width: rem(867px)) {
    grid-template-columns: auto auto;
    grid-template-areas: 'logo controls' 'navigation navigation';
  }
  .logo {
    grid-area: logo;
    margin-right: auto;
  }
  .navigation {
    grid-area: navigation;
    margin-left: auto;
    margin-right: auto;
  }
  .controls {
    grid-area: controls;
    margin-left: auto;
  }
}

.heading {
  text-align: center;
  font-size: rem(20px);
  font-weight: 500;
  line-height: rem(24px);
  @media (max-width: rem(767px)) {
    font-size: rem(15px);
    line-height: rem(18px);
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: rem(10px);
  flex-direction: row;
  position: relative;

  &Image {
    display: block;
    width: rem(31.5px);
    height: rem(20px);
    object-fit: contain;
    @media (max-width: rem(800px)) {
      width: rem(25px);
      height: rem(16px);
    }
  }
  &Text {
    text-transform: uppercase;
    font-size: rem(25px);
    font-weight: 600;
    line-height: rem(30px);
    @media (max-width: rem(800px)) {
      font-size: rem(20px);
      line-height: rem(24px);
    }
  }
}

.navigation {
  width: rem(350px);
  display: flex;
  gap: rem(10px);
  padding: rem(4px);
  background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
  border-radius: rem(15px);
  min-width: 0;
  @media (max-width: rem(767px)) {
    max-width: rem(325px);
  }
  @media (max-width: rem(575px)) {
    max-width: rem(300px);
  }
  &Button {
    flex-basis: 50%;
    text-align: center;
    font-size: rem(15px);
    line-height: rem(18px);
    font-weight: 500;
    border-radius: rem(15px);
    padding: rem(5px) rem(10px);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    @media (max-width: rem(575px)) {
      font-size: rem(12px);
      line-height: rem(16px);
    }
    &Active {
      background-color: var(--background-color);
      box-shadow: 0 rem(4px) rem(4px) rgba(0, 0, 0, 0.25);
    }
  }
}

.controls {
  display: flex;
  align-items: center;
}

.auth {
  display: flex;
  align-items: center;
  gap: rem(5px);
  margin-right: rem(10px);
  @media (max-width: rem(575px)) {
    display: none;
  }
  &Button {
    opacity: 1;
    transition: color 0.3s ease;
    &:hover {
      color: #c400d6;
    }
  }
}

.buttonPro {
  display: none;
  margin-right: rem(30px);
  text-transform: uppercase;
  font-size: rem(18px);
  font-weight: 700;
  line-height: rem(22px);
  padding: rem(4px) rem(12px);
  border-radius: rem(10px);
  background: linear-gradient(95deg, #c400d6 0%, #4628ff 100%);
  @media (max-width: rem(767px)) {
    font-size: rem(15px);
    line-height: rem(18px);
    padding: rem(3px) rem(9px);
    margin-right: rem(10px);
  }
}

.buttonFiller {
  width: rem(36px);
  height: rem(36px);
}

.loader {
  width: rem(30px);
  height: rem(30px);
}
