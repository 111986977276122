@import '~styles/mixins';

.profilePage {
  width: 100%;
  padding-top: rem(40px);
  padding-bottom: rem(60px);

  @media (max-width: rem(1150px)) {
    padding-bottom: rem(160px);
  }

  @media (max-width: rem(400px)) {
    padding-top: rem(2px);
  }

  .link {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .content {
    .loaderWrapper {
      width: 100%;
      height: rem(40px);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: rem(40px);

      .loader {
        width: rem(40px);
        height: rem(40px);
      }
    }

    .profile {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      @media (max-width: rem(972px)) {
        flex-direction: column;
        gap: rem(40px);
      }

      @media (max-width: rem(400px)) {
        gap: rem(20px);
      }

      .follow {
        font-weight: 600;
        font-size: rem(15px);
        line-height: rem(18px);
        padding: rem(11px) 0;
        background-image: linear-gradient(
          94.62deg,
          #c400d6 7.3%,
          #4628ff 99.05%
        );
        border-radius: rem(25px);
        width: rem(200px);
        max-width: rem(200px);
        transition: 0.3s;
        &.followed {
          box-shadow: rem(10px) rem(10px) rem(5px) rem(200px) rgba(0, 0, 0, 0.3)
            inset;
          -webkit-box-shadow: rem(10px) rem(10px) rem(5px) rem(200px)
            rgba(0, 0, 0, 0.3) inset;
          -moz-box-shadow: rem(10px) rem(10px) rem(5px) rem(200px)
            rgba(0, 0, 0, 0.3) inset;
        }
        @media (max-width: rem(400px)) {
          display: none;
        }

        &Mobile {
          display: none;
          @media (max-width: rem(400px)) {
            display: block;
            width: 100%;
            max-width: 100%;
            border-radius: rem(30px);
          }
        }
      }

      .information {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: rem(40px);

        @media (max-width: rem(400px)) {
          flex-direction: column;
          gap: rem(15px);
        }

        .avatarWrapper {
          width: rem(150px);
          height: rem(150px);
          background-color: hsla(251, 24%, 9%, 1);
          box-shadow: rem(0px) rem(2px) rem(7px) rgba(0, 0, 0, 0.5);
          border-radius: rem(40px);
          padding: rem(8px);
          overflow: hidden;
          position: relative;
          border: 1px solid hsla(248, 100%, 58%, 0.55);

          @media (max-width: rem(680px)) {
            width: rem(90px);
            height: rem(90px);
            border-radius: rem(30px);
            padding: rem(5px);
          }

          &::before {
            content: '';
            z-index: 1;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(
              45deg,
              hsla(295, 100%, 42%, 0.75) 0%,
              hsla(248, 100%, 58%, 0.75) 100%
            );
            transform: translate3d(rem(0px), rem(20px), 0) scale(0.95);
            filter: blur(rem(20px));
            opacity: 0.75;
            transition: opacity 0.3s;
            border-radius: inherit;
          }
          &::after {
            content: '';
            z-index: 0;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: inherit;
            border-radius: inherit;
          }

          .avatar {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: rem(40px);

            @media (max-width: rem(680px)) {
              border-radius: rem(30px);
            }
          }
        }

        .user {
          display: flex;
          flex-direction: column;
          gap: rem(40px);

          @media (max-width: rem(400px)) {
            gap: rem(30px);
          }

          .nicknameWrapper {
            display: flex;
            flex-direction: column;
            gap: rem(10px);

            @media (max-width: rem(400px)) {
              align-items: center;
            }

            .nickname {
              font-weight: 600;
              font-size: rem(20px);
              line-height: rem(24px);
              @media (max-width: rem(680px)) {
                font-size: rem(15px);
                line-height: rem(18px);
              }
            }

            .description {
              font-weight: 400;
              font-size: rem(15px);
              line-height: rem(18px);
              @media (max-width: rem(680px)) {
                font-size: rem(12px);
                line-height: rem(15px);
              }
            }

            .settingsLink {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: rem(10px);
              fill: white;
              transition: 0.3s;

              @media (max-width: rem(680px)) {
                font-size: rem(12px);
                line-height: rem(15px);
                gap: rem(5px);
              }

              .settingsIcon {
                @media (max-width: rem(680px)) {
                  width: rem(20px);
                  height: rem(20px);
                }
              }

              &:hover {
                fill: hsla(295, 100%, 78%, 1);
                color: hsla(295, 100%, 78%, 1);
              }
            }
          }
        }
      }

      .rightPart {
        display: flex;
        flex-direction: column;
        gap: rem(30px);
        align-items: flex-end;
        @media (max-width: rem(972px)) {
          align-items: center;
        }
      }

      .statistics {
        display: flex;
        flex-direction: row;
        gap: rem(40px);

        @media (max-width: rem(400px)) {
          gap: rem(35px);
        }
        @media (max-width: rem(310px)) {
          gap: rem(10px);
          width: 100%;
          justify-content: space-between;
        }

        .statistic {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: rem(10px);

          .title {
            font-weight: 400;
            font-size: rem(15px);
            line-height: rem(18px);
            @media (max-width: rem(680px)) {
              font-size: rem(13px);
              line-height: rem(16px);
            }
          }

          .value {
            font-weight: 700;
            font-size: rem(20px);
            line-height: rem(24px);
            @media (max-width: rem(680px)) {
              font-size: rem(17px);
              line-height: rem(21px);
            }
          }
        }
      }
    }

    .grid {
      padding-top: rem(40px);
      display: grid;
      grid-template-columns: repeat(4, minmax(rem(110px), rem(305px)));
      // grid-template-columns: repeat(4, minmax(rem(110px), rem(305px)));
      // grid-template-columns: repeat(auto-fill, minmax(rem(110px), rem(305px)));
      //   grid-template-rows: repeat(a, rem(305px));
      // justify-content: center;
      gap: rem(20px);

      @media (max-width: rem(1140px)) {
        grid-template-columns: repeat(3, minmax(rem(80px), rem(305px)));
      }

      @media (max-width: rem(600px)) {
        gap: rem(10px);
      }

      .wrapper {
        // height: 100%;
        // height: rem(305px);
        padding-bottom: 100%;
        height: 0;
        width: 100%;
        border-radius: rem(20px);
        overflow: hidden;
        position: relative;
        animation-name: appearing;
        animation-duration: 2s;

        @media (max-width: rem(600px)) {
          border-radius: rem(15px);
        }

        @keyframes appearing {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        .image {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
