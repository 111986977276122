.container {
  position: relative;
  width: calc(100% - 120px);
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  border-radius: 15px;
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 40px;
  }
}
