.text {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
}

.button {
  margin-top: 20px;
  width: 100%;
}
