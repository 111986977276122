@import '~styles/mixins';

.main {
  width: 100%;
  padding-top: rem(40px);
  padding-bottom: rem(40px);
}

.content{
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.goBackButton {
  width: rem(50px);
  height: rem(50px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: rem(20px);
  // margin-left: auto;
}