@import '~styles/mixins';
@import '~styles/variables';

.mobileContent {
  bottom: rem(0px);
  left: rem(0px);
  height: $bottomHeight;
  background-color: var(--background-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // bottom: rem(20px);
  // left: 50%;
  // transform: translateX(-50%);
  z-index: 30;
  width: 100%;
  padding: 0 rem(28px);

  @media (min-width: rem(1151px)) {
    display: none;
  }
  @media (max-width: rem(400px)) {
    // bottom: rem(23px);
  }
  @media (max-width: rem(390px)) {
    padding: 0 rem(10px);
  }

  .nav {
    width: 100%;
  }

  .nav__list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: rem(5px);
    font-weight: 600;
    font-size: rem(12px);
    line-height: rem(15px);
    list-style: none;
    max-width: rem(567px);
    margin: 0 auto;

    @media (max-width: rem(400px)) {
      & {
        font-size: rem(10px);
        line-height: rem(12px);
      }
    }
  }
  .nav__elem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: rem(5px);
    width: rem(69px);
    height: rem(69px);
    border-radius: rem(20px);
    text-align: center;
    border: 1px solid transparent;
    position: relative;
    transition: 0.3s;
    background-color: hsla(251, 24%, 9%, 1);

    @media (max-width: rem(400px)) {
      & {
        width: rem(64px);
        height: rem(64px);
      }
    }

    &.active {
      box-shadow: rem(0px) rem(2px) rem(7px) rgba(0, 0, 0, 0.5);
      border-color: hsla(295, 100%, 42%, 1);

      &:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(
          -45deg,
          hsla(295, 100%, 42%, 1) 0%,
          hsla(248, 100%, 58%, 1) 100%
        );
        background-size: 170% 170%;
        transform: translate3d(rem(0px), rem(0px), 0) scale(1.01);
        filter: blur(rem(8px));
        opacity: var(0.5);
        transition: opacity 0.3s;
        border-radius: inherit;
        animation: gradientTransfusionBG 10s infinite;
      }

      /* 
        * Prevents issues when the parent creates a 
        * stacking context. (For example, using the transform
        * property )
        */
      &::after {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        border-radius: inherit;
      }
    }
  }
  .icon {
    width: rem(31px);
    height: rem(31px);
    fill: white;
  }
}
.link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}

